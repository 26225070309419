/* JS for Styling */

(function ($) {

	"use strict";

	if(jQuery('.djc_additional_filters').length && jQuery('#breadcrumbs').length) {
		jQuery('.djc_additional_filters').after(jQuery('#breadcrumbs'));
	}

	if(jQuery('.djc_mainimage').length && jQuery('.djc_thumbnails').length) {
		var prev = jQuery('<div class="previous"></div>');
		var next = jQuery('<div class="next"></div>');
		var imgA = jQuery('#djc_mainimagelink');
		imgA.before(prev);
		imgA.after(next);
		prev.on('touchstart click', function (e) {
			e.preventDefault();
			var index = parseInt(imgA.attr('rel').replace('djc_lb_', ''));
			var current = jQuery(jQuery('.djc_thumbnails .djc_thumbnail').get(index));
			if(current.is(':first-child')) {
				jQuery('.djc_thumbnails .djc_thumbnail:last-child img').trigger('click');
			} else {
				current.prev().find('img').trigger('click');
			}
		});
		next.on('touchstart click', function (e) {
			e.preventDefault();
			var index = parseInt(imgA.attr('rel').replace('djc_lb_', ''));
			var current = jQuery(jQuery('.djc_thumbnails .djc_thumbnail').get(index));
			if(current.is(':last-child')) {
				jQuery('.djc_thumbnails .djc_thumbnail:first-child img').trigger('click');
			} else {
				current.next().find('img').trigger('click');
			}
		});
	}

	if(jQuery('.djc_list .djc_items').length) {
		jQuery('.djc_list .djc_items').alignHeight('> .djc_item', '.btn', 140, 768);
	}

	if(jQuery('.feature_video_video').length) {
		jQuery('.feature_video_video').lockRatio('width', (560 / 315));
	}

	if(jQuery('.djc_nav-tabs').length) {
		var tab_toggle = jQuery('<div class="djc_nav-toggle desktopHide"><span class="title"></span><span class="arrow"></span></div>');
		jQuery('.djc_nav-tabs .nav').before(tab_toggle);
		jQuery('.djc_nav-toggle, .djc_nav-tabs .nav li').on('click', function (e) {
			jQuery('.djc_nav-tabs .nav').toggleClass('show');
			tab_toggle.toggleClass('show');

			if(jQuery(this).is('li')) {
				//jQuery('.djc_nav-toggle .title').html((jQuery(this).find('a').html()));
				//jQuery('.djc_nav-toggle .title').html("");

			}

			if(tab_toggle.hasClass('show')) {
				e.stopPropagation();
				jQuery('#viewport').on('click.tab_toggle', function () {
					tab_toggle.trigger('click');
				});
				jQuery(window).on('scroll.tab_toggle', function () {
					var lastLi = jQuery('.djc_nav-tabs ul.nav > li:last-child');
					if(jQuery(window).scrollTop() > (lastLi.offset().top + lastLi.height())) {
						tab_toggle.trigger('click');
					}
				});
			} else {
				jQuery('#viewport').off('click.tab_toggle');
				jQuery(window).off('scroll.tab_toggle');
			}
		});
		jQuery('.djc_product_detail .djc_nav-toggle .title').html("More Product Information");
		jQuery('.djc_support_detail .djc_nav-toggle .title').html("More Support Information");
	}

	if(jQuery('.djc_product_detail h2.djc_title').length && jQuery('.djc_product_detail .djc_media').length) {
		var title = jQuery('.djc_product_detail h2.djc_title');
		var title2 = title.clone();
		title.addClass('mobileHide');
		title2.addClass('mobileOnly');
		jQuery('.djc_product_detail .djc_media').prepend(title2);
	}

	if(jQuery('.djc_productinfo h2.djc_title').length) {
		jQuery('.djc_productinfo h2.djc_title').each(function () {
			var share = jQuery('<i class="share"></i>');
			jQuery(this).append(share);
			share.on('touchstart click', function (e) {
				e.preventDefault();
				if(jQuery('.stButton').length != 0) {
					jQuery('.stButton').first().click();
				} else if(jQuery('.addthis_button_compact').length != 0) {
					jQuery('.addthis_button_compact').click();
				}
			});
		});
	}

	if(jQuery('.djc_support_detail a.contact_support').length) {
		var contact = jQuery('.djc_support_detail a.contact_support');
		var contact2 = contact.clone();
		contact.addClass('mobileHide');
		contact2.addClass('mobileOnly');
		contact2.addClass('arrow');
		jQuery('.djc_support_detail .djc_intro .djc_buttons').append(contact2);
	}

	if(jQuery('table.productTable').length) {
		jQuery('table.productTable').shadowScroller();
	}

	if(jQuery('.brochures .productDocument').length) {
		jQuery('.brochures').alignHeight(' .productDocument', '.blue.btn', 30, 768);
	}

	if(jQuery('.documents .productDocument').length) {
		jQuery('.documents').alignHeight(' .productDocument', '.blue.btn', 30, 768);
	}


	var review_btn = jQuery('.read-more-review-btn');
	var screensize = jQuery(window).width();
	review_btn.on('click', function (e) {

		if(screensize < 768) {
			jQuery('.prodAccordion').removeClass('prodActive');
			jQuery('.prodPanel').each(function () {
				jQuery(this).css('max-height', '');
			});

			var panelBtn = $('button#testimonials');
			var panel = $('div#testimonials');

			panelBtn.addClass("prodActive");
			panel.css('max-height', "100%");

			setTimeout(function () {
				var theOffset = panel.offset();

				jQuery('body,html').animate({scrollTop: theOffset.top - 100});
			}, 310);
		}
	});

	document.addEventListener("DOMContentLoaded", function (event) {
		var acc = document.getElementsByClassName("prodAccordion");
		var i;
		for (i = 0; i < acc.length; i++) {
			if(i == 0) {
				var panel = acc[i].nextElementSibling;
				panel.style.maxHeight = "inherit";
			}
			acc[i].addEventListener("click", function () {
				this.classList.toggle("prodActive");
				jQuery('.prodActive').not(this).each(function () {
					var panel = this.nextElementSibling;
					if(panel.style.maxHeight) {
						panel.style.maxHeight = null;
					}
					this.removeClass("prodActive");

				});

				var panel = this.nextElementSibling;

				if(panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + "px";
				}

				var self = this;
				setTimeout(function () {
					var theOffset = jQuery(self).offset();

					jQuery('body,html').animate({scrollTop: theOffset.top - 100});
				}, 310);

			});

		}
	});

})(jQuery);

